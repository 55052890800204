<template>
  <div v-if="isMounted" :id="name" class="slider swiper-container">
    <slot name="pagination" />
    <div class="swiper-wrapper">
      <slot name="slide" />
    </div>
  </div>
</template>

<script>
let Swiper
const getSwiper = () =>
  import(
    /* webpackChunkName: "swiper" */
    'swiper'
  )
export default {
  name: 'SwiperAbstraction',

  props: {
    name: [String, Number],
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      swiper: {},
      isMounted: false,
    }
  },

  computed: {
    swiperOptions() {
      const options = {
        ...this.options,
        on: {
          slideChange: () => this.$emit('slide-change'),
        },
      }
      return { ...options }
    },
  },

  beforeCreate() {
    if (process.server || Swiper) return

    this.$options.promise = getSwiper().then((module) => {
      Swiper = module.default
      return module
    })
  },

  mounted() {
    this.initSlider()
  },

  methods: {
    async initSlider() {
      await this.$options.promise
      this.isMounted = true
      await this.$nextTick()

      this.swiper = new Swiper(`#${this.name}`, this.swiperOptions)
      this.$emit('init')
    },
  },
}
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;
}
</style>
