<template>
  <SwiperAbstraction
    :ref="name"
    :name="name"
    :options="options"
    @slide-change="onSlideChange"
    @init="onInit"
  >
    <div
      v-for="(item, i) in images"
      slot="slide"
      :key="i"
      class="swiper-slide"
      @mouseenter="onEnter"
      @mouseleave="onLeave"
    >
      <CImage
        class="slider__img"
        is-external
        disable-loader
        :fallback="item"
        alt="image"
        @loaded="onLoaded"
      />
    </div>
  </SwiperAbstraction>
</template>

<script>
import CImage from '@/components/common/CImage'
import SwiperAbstraction from '@/components/ui/SwiperAbstraction'

export default {
  name: 'ProductCardSlider',

  components: {
    CImage,
    SwiperAbstraction,
  },

  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isEntry: false,
      imageLoaded: false,
    }
  },
  watch: {
    imageLoaded(value) {
      if (value && this.isEntry) {
        this.$refs[this.name].swiper.autoplay.start()
      }
    },
  },

  created() {
    const stamp = Math.random(Date.now()) * Date.now()
    /* TODO: Временное решение по скольку нет реальных данных */
    this.name = Math.round(stamp)

    this.options = {
      slidesPerView: 'auto',
      centeredSlides: true,
      speed: 1000,
      autoplay: {
        delay: 1000,
      },
      scrollbar: {
        hide: true,
      },
    }
  },

  methods: {
    onInit() {
      this.$refs[this.name]?.swiper?.autoplay?.stop()
    },

    onSlideChange() {
      const { realIndex, slides } = this.$refs[this.name].swiper
      if (realIndex && !slides[realIndex].querySelector('img').src) {
        this.imageLoaded = false
        this.$emit('image-loaded', this.imageLoaded)
      }
    },

    onEnter(e) {
      if (this.$refs[this.name].swiper?.autoplay?.running) return
      this.isEntry = true
      if (!this.imageLoaded) {
        this.$refs[this.name].swiper?.autoplay?.stop()
      } else {
        this.$refs[this.name].swiper?.autoplay?.start()
      }
    },

    onLeave() {
      this.isEntry = false
      this.$refs[this.name].swiper?.autoplay?.stop()
    },

    onLoaded() {
      this.imageLoaded = true
      this.$emit('image-loaded', this.imageLoaded)
    },
  },
}
</script>

<style lang="scss" scoped>
.slider {
  position: absolute;
  top: rem(-2);
  right: rem(-2);
  bottom: rem(-2);
  left: rem(-2);
  display: flex;
  width: 100%;
  height: 100%;
  min-height: inherit;
  overflow: hidden;
  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /deep/ .image {
      object-fit: cover;
      transform: translate3d(10%, 1%, 0);
    }
  }
}
.swiper-slide {
  width: 140%;
  max-width: 140%;
  height: 140%;
}

@media only screen and (max-width: $breakpoint-desktop) {
  .swiper-slide {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: $breakpoint-large-mobile) {
  .slider {
    &__img {
      display: inline-flex;
      /deep/.image {
        margin: 0 auto;
        object-fit: contain;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>
